import React from 'react';

import Layout from '../components/Layout';
import ConferencesMap from '../components/ConferencesMap';
import { Link, graphql } from 'gatsby';
import LanguageFlag from '../components/LanguageFlag';
import Photos from '../components/Photos';
import CoSpeakers from '../components/CoSpeakers';
import UpcomingTalks from '../components/UpcomingTalks';
import { isFutureEvent } from '../utils/utils';

const PublicSpeakingPage = ({ data }) => (
  <Layout fullMenu>
    <section id='wrapper'>
      <header>
        <div className='inner'>
          <h2>Public Speaking</h2>
          <div className='public-speaking-header'>
            <ConferencesMap />
            <UpcomingTalks talks={upcomingTalks(data)} />
          </div>
        </div>
      </header>

      <div className='wrapper'>
        <div className='inner'>
          <h3 className='major'>Talks</h3>
          <section className='features'>
            {data.allFile.nodes
              .map(loadTalk())
              .sort(
                (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
              )
              .map(talk => {
                const { defaultLanguage, cospeakers, languages } = talk;
                const { title } = talk.languages[defaultLanguage];
                return (
                  <article id={talk.id} key={talk.id}>
                    <Link to={`/talks/${talk.id}`} className='image'>
                      <img
                        src={require(`../assets/images/talks/${talk.id}.png`)}
                        alt=''
                      />
                    </Link>
                    <h3 className='major'>{title}</h3>
                    <div className='cospeakers-languages'>
                      <div>
                        <CoSpeakers cospeakers={cospeakers} />
                      </div>
                      <div className='languages'>
                        {Object.keys(languages).map(language => (
                          <LanguageFlag key={language} language={language} />
                        ))}
                      </div>
                    </div>
                    <Link className='special' to={`/talks/${talk.id}`}>
                      Discover
                    </Link>
                  </article>
                );
              })}
          </section>
          <Photos />
        </div>
      </div>
    </section>
  </Layout>
);

function loadTalk () {
  return talk => require(`../data/talks/${talk.name}.js`);
}

function upcomingTalks (data) {
  const talks = data.allFile.nodes;

  return talks
    .map(loadTalk())
    .filter(
      talk => talk.events.filter(event => isFutureEvent(event)).length > 0
    )
    .sort((a, b) => firstUpcomingDate(a) - firstUpcomingDate(b));
}

function firstUpcomingDate ({ events }) {
  return events
    .filter(event => event.date !== undefined)
    .map(event => new Date(event.date))
    .filter(date => date > Date.now())
    .reduce((acc, val) => (acc < val ? acc : val));
}

export const query = graphql`
  query {
    allFile {
      nodes {
        name
      }
    }
  }
`;

export default PublicSpeakingPage;
