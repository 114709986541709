import React from 'react';
import { Link } from 'gatsby';
import { isFutureEvent, getEventMarkerIcon } from '../utils/utils';
import {events} from '../data/events'

export default function UpcomingTalks({ talks }) {
    return (talks !== undefined && talks.length > 0 ?
        <div className='upcoming-talks'>
            <h3>Upcoming Talks</h3>
            <ul className='alt'>
                {
                    talks.map(talk => {
                        const { id, defaultLanguage, languages } = talk

                        return (<li className='upcoming-talk' key={id}>
                            <Link to={`/public-speaking#${id}`}>
                                <h4>{languages[defaultLanguage].title}</h4>
                                {
                                    talk.events.filter(event => isFutureEvent(event))
                                        .map(event =>
                                            <div className='upcoming-talk-event' key={event.id}>
                                                <div className='date'>{new Date(event.date).toDateString()}</div>
                                                <div className='event'>
                                                    <img className='event-icon' src={getEventIcon(event)} />
                                                    <div className='event-name'>{getEventName(event)}</div>
                                                </div>
                                            </div>)
                                }
                            </Link>
                        </li>)
                    })
                }
            </ul>
        </div>
        : null
    )
}

function getEventName(event) {
    return events.find(e => event.id === e.id).title
}

function getEventIcon(event) {
    return getEventMarkerIcon(events.find(e => event.id === e.id))
}
