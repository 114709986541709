import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { photos } from '../data/photos';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {},
}));

export default function Photos () {
  const classes = useStyles();
  const theme = useTheme();
  const xsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <h3 className='major'>Photos</h3>
      <div className={classes.root}>
        <GridList
          cellHeight={xsmallScreen ? 150 : 300}
          cellclassname={classes.gridList}
          cols={xsmallScreen ? 1 : 3}
        >
          {photos.map(photo => (
            <GridListTile key={photo.url} cols={photo.cols || 1}>
              <img className='photo' src={photo.url} />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  );
}
